@import 'src/uikit/stylesheets/styles.scss';

.blacklists-table-cell {
  vertical-align: middle;
  @extend %body;
}

.blacklists-table-cell-actions {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.blacklists-loader-placeholder {
  border-radius: 4px;
}
