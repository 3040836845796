@import 'src/styles';

.blacklists-filters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.blacklists-filter-wrapper {
  width: 100%;
  max-width: 275px;
  min-width: 275px;
}

.blacklists-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;

  .blacklists-date-range-filters-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 22px;
  }
}

.export-blacklists-reports-actions-wrapper {
  display: flex;
  gap: 20px;
}

.blacklists-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  padding-right: 12px;
  padding-left: 12px;
  gap: 5px;
}
