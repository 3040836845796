@import 'src/uikit/stylesheets/styles.scss';

.payments-cutoff-times-table-cell {
  vertical-align: middle;
  @extend %body;
}

.payments-cutoff-times-loader-placeholder {
  border-radius: 4px;
}

.payments-cutoff-times-time-input {
  width: 110px;
  min-width: 110px;
}

.small-title {
  font-size: $font-size-small;
  font-style: italic;
  color: $neutral;
}
