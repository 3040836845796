.monitoring-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;

  .monitoring-actions-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    //margin-bottom: 30px;

    .monitoring-button {
      padding-right: 12px;
      padding-left: 12px;
      transition: color 0.3s, border-color 0.3s;
      width: fit-content !important;
    }
  }

  .monitoring-container-pop-up {
    overflow: scroll;
  }
}

.monitoring-tab-loader {
  height: calc(100vh - 209px) !important;
}