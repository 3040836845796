@import 'src/styles';

.scheme-wrapper-add-new-provider {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 500px;
  gap: 25px;

  .scheme-header-add-new-provider {
    @extend %heading-1;
  }

  .add-new-provider-scheme-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
