.documents-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  gap: 16px;

  .documents-container-button {
    padding-right: 12px;
    padding-left: 12px;
    transition: color 0.3s, border-color 0.3s;
    width: fit-content !important;
  }

  .documents-container-popUp {
    width: 70vw;
  }
}

.documents-tab-loader {
  height: calc(100vh - 209px) !important;
}