@import 'src/uikit/stylesheets/styles.scss';

.transit-transactions-table-cell {
  vertical-align: middle;
  @extend %body;
  white-space: pre-wrap;
}

.transit-transactions-status-details-table-cell {
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  word-break: break-word;
}

.transit-transactions-table-cell-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 120px;

  .transit-transactions-action-button {
    height: 40px;
    line-height: 14px;
  }
}

.transit-transaction-id-text {
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.transit-transactions-loader-placeholder {
  border-radius: 4px;
}
