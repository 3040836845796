.logs-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;

  .logs-container-table {
    width: 100%;
  }
}

.logs-tab-loader {
  height: calc(100vh - 209px) !important;
}