@import 'src/styles';

.async-select-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    position: absolute;
    top: -10px;
    left: 8px;
    padding: 0 8px 2px 8px;
    border-radius: 6px;
    line-height: 11px;
    font-size: $font-size-smaller;
    color: $dark;
    z-index: 1;
  }

  .async-select {
    width: 100%;
  }

  .input-sub-text {
    text-align: left;
    margin: 5px 5px 0 10px;
    font-size: $font-size-small;
    color: $dark;
    animation: 0.2s anim-lineUp ease-out;
  }

  .input-error {
    color: $error;
    text-align: left;
  }

  &.error {
    .async-select {
      div:first-of-type {
        border-color:  $error !important;
      }
    }
    label {
      color: $error;
    }
    svg {
      color: $error;
    }
  }
}
