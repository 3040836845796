@import 'src/styles';

.transit-transaction-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;
}

.transit-transaction-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  padding: 20px 175px 0 175px;
}

.transit-transaction-block-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.transit-transaction-row-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}

.transit-transaction-select-transaction-type {
  width: 100%;
  min-width: 160px;
  max-width: 150px;
}

.transit-transaction-select {
  width: 100%;
  min-width: 160px;
  max-width: 200px;
}

.transit-transaction-select-currency {
  width: 100%;
  min-width: 160px;
  max-width: 200px;
  input {
    padding-left: 8px !important;
    height: 60px !important;
    border-color: $light-neutral !important;
  }
}

.transit-transaction-input {
  width: 100%;
  min-width: 160px;
}

.transit-transaction-date-input {
  width: 100%;
  min-width: 160px !important;
  max-width: 200px;

  .datepicker-block {
    height: 60px !important;
    align-items: center;
    align-content: center;
  }
}

.transit-transaction-submit-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.transit-transaction-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: fit-content !important;
  padding: 6px 12px;
  box-sizing: border-box;
}
