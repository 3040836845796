@import 'src/styles';

.operator-select-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .operator-select {
    width: 100%;
  }

  .input-sub-text {
    text-align: left;
    margin: 5px 5px 0 10px;
    font-size: $font-size-small;
    color: $dark;
    animation: 0.2s anim-lineUp ease-out;
  }

  .input-error {
    color: $error;
    text-align: left;
  }

  &.error {
    .operator-select {
      div:first-of-type {
        border-color:  $error !important;
      }
    }
    label {
      color: $error;
    }
    svg {
      color: $error;
    }
  }
}
