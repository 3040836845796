@import 'src/styles';

.transit-transaction-async-select-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}

.transit-transaction-async-select-input {
  width: 100%;
  min-width: 300px;

  .async-select {
    height: 60px !important;
  }

  div:first-of-type {
    height: 100%;
    align-items: center;
    align-content: center;
  }
}
