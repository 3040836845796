@import 'src/styles';

.corporate-customer-details-container {
  padding: 100px 30px 30px 30px;
}

.customers-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.export-csv-reports-wrapper {
  display: flex;
  gap: 20px;
}

.customers-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  padding-right: 12px;
  padding-left: 12px;
  gap: 5px;
}

.corporate-customer-details-loader {
  height: calc(100vh - 209px) !important;
}
