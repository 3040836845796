@import 'src/styles';

.messages-topics-filters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.messages-topics-filter-wrapper {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
}

.messages-topics-checkbox {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  text-wrap: nowrap;
  gap: 5px;
  padding: 20px 10px 0 10px !important;
  background-color: inherit !important;

  &:first-child {
    width: fit-content;
  }
}

.messages-topics-date-range-filters-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  margin-bottom: 10px;

  .messages-topics-date-range-filter-block {
    width: 100%;

    .messages-topics-date-range-filter {
      width: 100%;
      max-width: 300px;
    }
  }
}

.datepicker-width {
  min-width: 210px !important;
}
