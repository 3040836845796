@import 'src/styles';

.commission-transactions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;

  .transactions-list {
    width: 100%;
  }
}
