.transactionNotes-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;

  .transactionNotes-container-addNote {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .transactionNotes-container-addNote-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px !important;
      height: fit-content !important;
      padding: 6px 12px;
      box-sizing: border-box;
    }
  }
}

.transaction-notes-tab-loader {
  height: calc(100vh - 209px) !important;
}