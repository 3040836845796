.aml-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;

  .aml-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
  }

  .aml-container-table {
    width: 100%;
  }
}

.aml-table {
  th {
    justify-content: center;
    align-items: center;
  }
}

.aml-add-document-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.aml-add-document-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px !important;
  height: 40px !important;
  padding: 6px 12px;
  box-sizing: border-box;
}

.aml-tab-loader {
  height: calc(100vh - 209px) !important;
}

.image-upload-container {
  width: 100%;
  text-align: center;
}

.image-wrapper {
  position: relative;
  margin-bottom: 16px;
}

.uploaded-image {
  width: 90%;
}

.delete-icon {
  cursor: pointer;
  position: absolute;
}

.file-selector-wrapper {
  margin-top: 12px;
}

.document-wrapper {
  position: relative;
}

.document-link {
  cursor: pointer;
}
