@import 'src/uikit/stylesheets/styles.scss';

.monitoring-table-cell {
  @extend %body;

  .monitoring-table-cell-info {
    display: flex;
    flex-direction: column;

    .monitoring-table-cell-info-link {
      cursor: pointer;
      color: $active-default;
      text-decoration: none;

      :hover {
        color: $active-hover;
      }

      :active {
        color: $active-active;
      }
    }
    .monitoring-table-cell-info-date {
      @include caption();
    }
  }
}

.monitoring-loader-placeholder {
  border-radius: 4px;
}
