@import 'src/styles';

.card-block-scheme-wrapper {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 30px 40px;

  .card-block-scheme-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}