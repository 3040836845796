@import 'src/uikit/stylesheets/styles.scss';

.topic-messages-table-cell {
  min-width: 125px;
  vertical-align: middle;
  @extend %body;
  word-break: break-word;
}

.message-table-cell {
  max-width: 65%;
  width: 100%;
}

.message-documents-table-cell {
  max-width: 25%;
  width: 100%;
}

.topic-message-id-text {
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.message-document-button {
  @extend %body;
  width: fit-content;
  height: fit-content;
  text-align: left;
}

.topic-messages-loader-placeholder {
  border-radius: 4px;
}

.message-header {
  color: #7f8da0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.message-attachments {
  margin-top: 20px;
  color: #7f8da0;
}
