@import 'src/uikit/stylesheets/styles.scss';

.payment-obligations-table-cell {
  vertical-align: middle;
  @extend %body;
}

.payment-obligations-table-cell-actions {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.payment-obligation-id-text {
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}
.payment-obligations-nav-link-text {
  width: fit-content;
  height: fit-content;
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}


.payment-obligations-loader-placeholder {
  border-radius: 4px;
}
