@import 'src/styles';

.users-container {
  padding: 100px 30px 30px 30px;

  .users-actions-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;

    .users-filters-inputs {
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .users-list {
    width: 100%;
  }
}

.users-filters-wrapper {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
}

.users-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  padding-right: 12px;
  padding-left: 12px;
  gap: 5px;
}
