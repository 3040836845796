@import 'src/styles';

.system-accounts-container {
  padding: 100px 30px 30px 30px;

  .system-accounts-actions-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
  }

  .accounts-list {
    width: 100%;
  }
}

.loader-placeholder {
  border-radius: 6px;
  height: 11px;
  width: 85px;
  background: linear-gradient(-90deg, $light-neutral 0%, lighten($light-neutral, 9%) 50%, $light-neutral 100%);
  background-size: 400% 400%;
  animation: stemPulse 1.2s ease-in-out infinite;
}

.system-accounts-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  padding-right: 12px;
  padding-left: 12px;
  gap: 5px;
}
