@import 'src/styles';

.new-message-container {
  padding: 60px 30px 30px 30px;

  .new-message-form-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 30px;
    border-radius: 6px;

    .new-message-form {
      display: flex;
      flex-direction: column;
      width: 100%;

      .new-message-form-client-input {
        margin-bottom: 36px;
      }

      .new-message-form-subject-input {
        height: fit-content;
        margin-bottom: 6px;
        @extend %body;
        color: $header;
      }

      .new-message-form-announcement-checkbox {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        margin-bottom: 16px;
        background-color: inherit !important;

        &:first-child {
          width: fit-content;
        }
      }

      .new-message-form-message-input {
        height: fit-content;

        textarea {
          padding: 16px;
          height: fit-content;
          resize: none;
          @extend %body;
          color: $header;
        }
      }

      .new-message-form-attachments {
        margin-top: 40px;

        .input {
          padding: 15px;

          .files-list-wrapper {
            width: 100%;
          }
        }
      }

      .new-message-form-button {
        margin-top: 40px;
      }
    }
  }
}
