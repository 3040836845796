@import 'src/styles';

.scheme-wrapper-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 500px;
  max-width: 600px;
  box-sizing: border-box;

  .scheme-wrapper-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .scheme-wrapper-container-header-close-icon {
      @extend %close-icon;
      color: $header;

      .background {
        color: transparent;
      }
    }
  }
  .scheme-wrapper-container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: fit-content !important;
    height: 40px !important;
    padding: 6px 12px;
    box-sizing: border-box;
  }

  .scheme-wrapper-container-marked {
    font-weight: bold;
  }

  .scheme-wrapper-container-hold {
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-radius: 6px;
  }
}

.account-details-edit-input {
  width: 100%;
  input {
    height: 40px !important;
    border-color: $light-neutral !important;
  }

  div:last-of-type {
    top: 40px !important;
  }

  div:first-of-type {
    top: 10.5px !important;
  }
}

.account-details-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
}
