@import 'src/styles';

.scheme-wrapper-add-new-account {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 500px;
  gap: 25px;

  .form-add-new-account-scheme {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;
  }

  .scheme-header-add-new-account {
    @extend %heading-1;
  }

  .add-new-account-scheme-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.add-new-account-input-dropdown-search {
  width: 100%;
  input {
    padding-left: 8px !important;
    height: 40px !important;
    border-color: $light-neutral !important;
  }

  .input-icon {
    top: 12px !important;
  }
}
