@import 'src/styles';

.limit-group-details-table-actions-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 10px;

  .limit-group-details-table-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content !important;
    padding-right: 14px;
    padding-left: 14px;
    margin-bottom: 20px;
    gap: 5px;
    font-weight: 600;
  }
}
