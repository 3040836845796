@import 'src/styles';

.customer-details-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.customer-details-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.customer-details-container {
  min-height: calc(100vh - 209px);
  padding: 80px 30px 30px 30px;
  justify-content: start;
}

.customer-details-loader {
  height: calc(100vh - 209px) !important;
}

.delete-customer-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}

.delete-customer-button {
  color: $bg-danger !important;
  border-color: $bg-danger !important;
  width: fit-content !important;
  padding-right: 12px;
  padding-left: 12px;
  transition: color 0.3s, border-color 0.3s;
}

.delete-customer-button:hover {
  color: darken($bg-danger, 15%) !important;
  border-color: darken($bg-danger, 15%) !important;
}

.delete-customer-button:active {
  color: darken($bg-danger, 25%) !important;
  border-color: darken($bg-danger, 25%) !important;
}

.table-wrapper {
  width: 100%;
}

.table-cell {
  min-width: 150px;
  width: fit-content;
  padding: 8px;
  border: 1px solid $light-neutral;
  white-space: pre-line;
  word-break: break-word;
}

.subsection {
  font-weight: bold;
  font-size: large;
}

.table-row .table-cell:first-child {
  max-width: 25%;
  width: 100%;
}

.table-row .table-cell:last-child {
  max-width: 75%;
  width: 100%;
}

.customer-password-actions-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;
  gap: 15px;
}

.customer-details-tab-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px !important;
  height: fit-content !important;
  padding: 6px 12px;
  box-sizing: border-box;
}

.customer-details-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .input-icon {
    top: 10.5px
  }
}

.input-with-save-button-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.table-cell-span-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-cell-span {
  display: flex;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
}

.table-cell-date-input {
  width: 100%;
}

.table-cell-input {
  width: 100%;
  input {
    height: 40px !important;
    border-color: $light-neutral !important;
  }

  div:last-of-type {
    top: 40px !important;
  }

  div:first-of-type {
    top: 10.5px !important;
  }
}

.table-cell-input-dropdown-search {
  width: 100%;
  input {
    padding-left: 8px !important;
    height: 40px !important;
    border-color: $light-neutral !important;
  }
}

.bottom-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px
}

.changes-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 20px
}

@include mobile {
  .customer-details-container {
    padding: 0 0 15px 0;
  }
}
