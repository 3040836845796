@import '../stylesheets/styles';

.date-icon {
  right: 0 !important;
  color: $header !important;
  rect {
    color: $white !important;
  }
}

.date-picker-error {
  text-align: left;
  margin: 5px 5px 0 10px;
  font-size: $font-size-small;
  color: $error;
  animation: 0.2s anim-lineUp ease-out;
}

.error {
  .datepicker-block {
    border-color:  $error !important;

    .date-icon {
      color: $error !important;
    }
    label {
      color: $error !important;
    }
  }
}

