@import 'src/styles';

.external-transaction-party-control-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.external-transaction-party-control-block-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.external-transaction-party-control-party-info-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}

.external-transaction-party-control-party-tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.external-transaction-party-control-input {
  width: 100%;
  min-width: 300px;
}

.external-transaction-party-control-search-select {
  width: 100%;
  min-width: 300px;
  input {
    padding-left: 8px !important;
    height: 60px !important;
    border-color: $light-neutral !important;
  }
}

.external-transaction-party-control-select {
  width: 100%;
  min-width: 175px;
  max-width: 200px;
}
