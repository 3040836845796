@import 'src/uikit/stylesheets/styles.scss';

.representatives-table-head {
  vertical-align: middle;
  @extend %body;
}

.representatives-table-head-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}