@import './src/styles';

.dont-permission-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  min-height: 55vh;

  .dont-permission-text {
    @extend %heading-1;
    color: $dark;
    text-align: center;
    white-space: pre-line;
  }
}