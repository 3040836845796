@import 'src/styles';

.customers-filters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;

  .filter-wrapper {
    width: 100%;
    min-width: 300px;
    max-width: 300px;
  }
}

.customers-filters-top-bar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  margin-bottom: 30px;
}

.customers-date-range-filters-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 22px;
}

.customers-filters-top-bar-actions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.customers-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  padding-right: 12px;
  padding-left: 12px;
  gap: 5px;
  min-width: 140px;
}
