@import 'src/styles';

.exchange-rates-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;

  .exchange-rates-actions-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;

    .exchange-rates-filters-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      gap: 20px;

      .exchange-rates-filter-wrapper {
        width: 100%;
        max-width: 272px;
        min-width: 272px;
        margin-top: 10px;

        input {
          padding-left: 8px !important;
          border-color: $light-neutral !important;
        }
      }
    }

    .exchange-rates-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content !important;
      padding-right: 14px;
      padding-left: 14px;
      gap: 5px;
      margin-top: 5px;
      font-weight: 600;
    }
  }

  .exchange-rates-list {
    width: 100%;
  }
}

.exchange-rates-filters-wrapper {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
}
