@import 'src/uikit/stylesheets/styles.scss';

.message-topics-table-cell {
  vertical-align: middle;
  @extend %body;
}

.message-topics-table-cell-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80px;

  .message-topics-release-money-button {
    height: 40px;
    line-height: 14px;
  }
}

.message-topic-subject-wrapper {
  max-width: 400px;
}

.message-topic-name {
  @extend %heading-2;
  word-break: break-word;
}

.is-unread {
  font-weight: 600;
}

.message-topic-last-message {
  @extend %caption;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-topic-id-text {
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.message-topics-loader-placeholder {
  border-radius: 4px;
}
