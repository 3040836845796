@import 'src/styles';

.internal-crypto-transaction-party-control-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
  padding-top: 10px;
}

.internal-crypto-transaction-party-control-account {
  width: 100%;
  min-width: 300px;

  .async-select {
    height: 60px !important;
  }

  div:first-of-type {
    height: 100%;
    align-items: center;
    align-content: center;
  }
}

.internal-crypto-transaction-party-control-select {
  width: 25%;
  min-width: 175px;
}

.internal-crypto-transaction-party-control-input {
  width: 100%;
  min-width: 300px;
}
