@import '../../../styles';

.document-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 32px;
  align-items: flex-end;

  .document-container-header {
    width: 100%;
    white-space: nowrap;
    @extend %heading-2;


    .document-container-header-icon {
      @extend %close-icon;
      color: $header;
      .background {
        color: transparent;
      }
    }
  }



  .document-container-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;

    .document-container-form-wrapper {
      display: flex;
      flex-direction: row;
      gap: 16px;


      .document-container-form-wrapper-input {
        width: 25%;
      }

      .document-container-form-wrapper-attach {
        width: 50%;
        height: 40px;
        margin-top: 22px;
      }

      .document-container-form-wrapper-attach-input .input {
        min-height: 0;
        height: 40px;
      }
      .document-container-form-wrapper-attach-input-error{
        border-color: red;

      }
      .document-container-form-wrapper-attach-input-error-text {
        text-align: left;
        margin: 5px 5px 0 10px;
        font-size: $font-size-small;
        animation: 0.2s anim-lineUp ease-out;
        color: $error;
      }
    }

    .document-container-form-button {
      width: 100%;
      display: flex;
        justify-content: flex-end;
    }
  }
}