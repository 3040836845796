@import 'src/uikit/stylesheets/variables.scss';

.pagination-wrapper {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;

  .pagination-size-dropdown {
    max-width: 120px;
    height: 30px;
    @include annotation();

    .input-dropdown-menu {
      top: 30px;
    }

    .input-icon {
      top: 5.5px
    }

    input {
      height: 30px;
    }

    .loader-placeholder {
      width: 120px;
      height: 30px;
    }
  }

  .pagination-pages {
    display: flex;
    gap: 5px;
    button {
      all: unset;
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background-color: $bg-2;
      @include annotation();

      svg {
        color: $active-default;
        width: 16px;
        height: 18px;
      }

      &:hover {
        background-color: $active-hover;
      }

      &:active, &.active {
        background-color: $active-default;
        color: $white;

        svg {
          color: $white;
        }
      }
    }

    .loader-placeholder {
      height: 40px;
      width: 40px;
    }
  }

  .pagination-counter {
    @extend  %caption;
    color: $light-neutral;
    strong {
      color: $dark-neutral;
    }

    .loader-placeholder {
      height: 22px;
      width: 52px;
    }
  }
}

@include mobile {
  .pagination-wrapper {
    margin: 30px 0 0 0;
    padding: 20px;
    gap: 20px;
    width: 100%;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;
    background-color: $white;
  }
}