@import 'src/styles';

.limit-list-filters-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.limit-list-filter-wrapper {
  width: 100%;
  max-width: 272px;
  min-width: 272px;
  margin-top: 10px;
}

.limit-list-filter-wrapper-input-dropdown-search {
  width: 100%;
  max-width: 272px;
  min-width: 272px;
  margin-top: 10px;

  input {
    padding-left: 8px !important;
    border-color: $light-neutral !important;
  }
}

.limit-list-filter-checkbox {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  gap: 10px;
  margin-top: 10px;
  background-color: inherit !important;
  padding: 0 !important;
}
