@import 'src/styles';

.tariff-input-control {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px 0;

  .tariff-fixed-value-wrapper {
    display: flex;
    flex-direction: row;
    border: 1px solid $light-neutral;
    border-radius: 6px;

    .table-cell-input input {
      border: none !important;
    }

    .tariff-fixed-value-currency {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $light;
      padding: 8px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.tariff-scheduled-table-wrapper {
  width: 100%;
}

.tariff-scheduled-table-cell {
  min-width: 110px;
  width: fit-content;
  padding: 8px;
  border: 1px solid $light-neutral;
  white-space: pre-line;
  word-break: break-word;
}

.tariff-scheduled-table-body {
  width: 100%;
}

.tariff-scheduled-table-row {
  width: 100%;
}

.tariff-scheduled-table-row .tariff-scheduled-table-cell:first-child {
  width: 100%;
}

.tariff-scheduled-table-row .tariff-scheduled-table-cell:last-child {
  width: 130px;

  .tariff-scheduled-table-input-wrapper {
    width: 110px;

    input {
      width: 110px;
    }
  }
}

.tariff-scheduled-table-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .input-icon {
    top: 10.5px
  }

  .tariff-scheduled-table-cell-input-timepicker {
    width: 110px;
    min-width: 110px;
  }

  .tariff-scheduled-table-cell-input {
    width: 100%;
    input {
      height: 40px !important;
      border-color: $light-neutral !important;
    }

    div:last-of-type {
      top: 40px !important;
    }

    div:first-of-type {
      top: 10.5px !important;
    }
  }
}
