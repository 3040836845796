@import 'src/styles';

.custom-tabs-wrapper-vertical {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;

  .icon-button-wrapper {
    width: 100%;
  }

  .custom-tab-button {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    border-right: 1px solid #ddd;
  }

  .custom-tab-button-active {
    border: 1px solid #ddd;
    border-right: 0;
    font-weight: 600;
  }
}

.custom-tabs-wrapper-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;

  .icon-button-wrapper {
    width: 100%;
  }

  .custom-tab-button {
    text-wrap: nowrap;
    padding: 20px 25px 20px 5px;
    border-bottom: 1px solid $light-neutral;
    &:active, &.active {
      border-bottom: 1px solid $active-default;
    }
  }
}
