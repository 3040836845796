@import 'src/styles';

.limit-groups-container {
  padding: 100px 30px 30px 30px;

  .limit-groups-actions-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;

    .limit-groups-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content !important;
      padding-right: 14px;
      padding-left: 14px;
      gap: 5px;
      font-weight: 600;
    }
  }

  .limit-groups-list {
    width: 100%;
  }
}

.limit-groups-filters-wrapper {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
}
