@import 'src/styles';

.limit-value-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px 0;

  .limit-value-wrapper-hold-input {
    display: flex;
    flex-direction: row;
    border: 1px solid $light-neutral;
    border-radius: 6px;
    .table-cell-input input {
      border: none !important;
    }
    .limit-value-wrapper-hold-currency {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $light;
      padding: 8px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .limit-value-wrapper-reject-input {
    display: flex;
    flex-direction: row;
    border: 1px solid $light-neutral;
    border-radius: 6px;
    .table-cell-input input {
      border: none !important;
    }
    .limit-value-wrapper-reject-currency {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $light;
      padding: 8px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}