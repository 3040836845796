@import 'src/uikit/stylesheets/styles.scss';

.topic-message-form {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: fit-content;

  .topic-message-form-message-input {
    height: fit-content;

    textarea {
      padding: 16px;
      height: fit-content;
      resize: none;
      @extend %body;
      color: $header;
    }
  }

  .topic-message-form-attachments {
    margin-top: 40px;

    .input {
      padding: 15px;

      .files-list-wrapper {
        width: 100%;
      }
    }
  }

  .topic-message-form-button {
    margin-top: 40px;
  }
}
