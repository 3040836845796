@import 'src/styles';

.plug-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  padding: 20px;
  gap: 40px;

  .plug-icon {
    width: 250px;
  }

  .plug-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;

    .plug-info-header {
      @extend %heading-1;
      width: 100%;
      text-align: left;
    }

    .plug-info-description {
      @extend %body;
      width: 100%;
      text-align: left;
    }
  }

  .dont-permission-text {
    @extend %heading-1;
    color: $dark;
    text-align: center;
    white-space: pre-line;
  }
}