@import 'src/uikit/stylesheets/styles.scss';

.logs-table-cell {
  @extend %body;

  .logs-table-cell-info {
    display: flex;
    flex-direction: column;
    .logsTab-table-cell-info-date {
      @include caption();
    }
  }
}

.logs-loader-placeholder {
  border-radius: 4px;
}
