@import 'src/uikit/stylesheets/styles.scss';

.customer-note-table-cell {
  @extend %body;

  .customer-note-table-cell-note-info {
    display: flex;
    flex-direction: column;
    .customer-note-table-cell-note-info-created-date {
      @include caption();
    }
  }

  .customer-notes-table-cell-note {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    word-break: break-all;

    .customer-notes-table-cell-note-delete-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
}

.document-link {
  width: fit-content;
  height: fit-content;
  text-align: left;

  cursor: pointer;

  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.customer-note-loader-placeholder {
  border-radius: 4px;
}
