@import 'src/styles';

.payments-cutoff-times-tab-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;

  .payments-cutoff-times-tab-actions-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-top: 5px;

    .payments-cutoff-times-tab-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content !important;
      padding-right: 12px;
      padding-left: 12px;
      gap: 5px;
    }
  }

  .accounts-list {
    width: 100%;
  }
}


.loader-placeholder {
  border-radius: 6px;
  height: 11px;
  width: 85px;
  background: linear-gradient(-90deg, $light-neutral 0%, lighten($light-neutral, 9%) 50%, $light-neutral 100%);
  background-size: 400% 400%;
  animation: stemPulse 1.2s ease-in-out infinite;
}

