@import 'src/uikit/stylesheets/styles.scss';

.customer-limit-details-table-row-individual {
  background-color: rgba($active-hover, 0.03) !important;

  .customer-limit-details-table-cell {
    background-color: rgba($active-hover, 0.03) !important;
  }
}

.customer-limit-details-table-row-updated {
  background-color: rgba($active-hover, 0.06) !important;

  .customer-limit-details-table-cell {
    background-color: rgba($active-hover, 0.06) !important;
  }
}

.customer-limit-details-table-cell {
  vertical-align: middle;
  @extend %body;
  padding: 0.75rem !important;
  white-space: pre-wrap !important;
}

.customer-limit-details-table-loader-placeholder {
  border-radius: 4px;
}

.customer-limit-details-table-customers-count-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.customer-limit-details-table-status-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .customer-limit-details-table-status-checkbox {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    background-color: inherit !important;
    padding: 0 !important;
  }
}

.customer-limit-details-table-date-and-time {
  @extend %caption;
  color: $neutral;
  text-align: left;
  white-space: pre-line;
  font-style: italic;
}
