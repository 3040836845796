@import 'src/styles';

.email-statistics-filters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.email-statistics-filter-wrapper {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
}

.email-statistics-date-range-filters-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  margin-bottom: 10px;
}
