@import 'src/styles';

.markup-groups-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;

  .markup-groups-actions-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;

    .markup-groups-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content !important;
      padding-right: 14px;
      padding-left: 14px;
      gap: 5px;
      font-weight: 600;
    }
  }

  .markup-groups-list {
    width: 100%;
  }
}

.markup-groups-filters-wrapper {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
}
