@import 'src/styles';

.customer-details-container {
  padding: 90px 30px 30px 30px;
}

.customer-details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 30px;
  gap: 20px;
}

.export-csv-reports-wrapper {
  display: flex;
  gap: 20px;
}

.customer-details-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  padding-right: 12px;
  padding-left: 12px;
  gap: 5px;
}


.customer-details-table-wrapper {
  width: 100%;
}

.customer-details-table-cell {
  min-width: 150px;
  width: fit-content;
  padding: 8px;
  border: 1px solid $light-neutral;
}

.customer-details-table-row:first-child .customer-details-table-cell:first-child {
  width: 30%;
}

.customer-details-table-row:first-child .customer-details-table-cell:last-child {
  width: 70%;
}

.customer-detail-input-and-button-field-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;
  gap: 15px;
}

.customer-detail-input-and-button-field-button  {
  width: fit-content !important;
  padding-right: 12px;
  padding-left: 12px;
}

.customer-details-input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.customer-details-table-cell-input {
  width: 100%;
  input {
    height: 40px !important;
    border-color: $light-neutral !important;
  }

  div:last-of-type {
    top: 40px !important;
  }

  div:first-of-type {
    top: 10.5px !important;
  }
}

.individual-customer-details-loader {
  height: calc(100vh - 209px) !important;
}
