@import 'src/uikit/stylesheets/styles.scss';

.commission-accounts-table-cell {
  vertical-align: middle;
  @extend %body;
}

.customer-id-text {
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.commission-accounts-loader-placeholder {
  border-radius: 4px;
}