@import 'src/uikit/stylesheets/styles.scss';

.email-statistics-table-cell {
  vertical-align: middle;
  @extend %body;
}

.email-statistics-table-cell-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80px;

  .email-statistics-release-money-button {
    height: 40px;
    line-height: 14px;
  }
}

.email-statistics-nav-link-text {
  width: fit-content;
  height: fit-content;
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.email-statistics-loader-placeholder {
  border-radius: 4px;
}
