@import 'src/uikit/stylesheets/styles.scss';

.transactionNote-table-cell {
  @extend %body;

  .transactionNote-table-cell-noteInfo {
    display: flex;
    flex-direction: column;
    .transactionNote-table-cell-noteInfo-createdDate {
      @include caption();
    }
  }

  .transactionNote-table-cell-id-text {
    color: $active-default;
    text-decoration: none;

    :hover {
      color: $active-hover;
    }

    :active {
      color: $active-active;
    }
  }

  .transactionNotes-table-cell-note {
    word-break: break-all;
  }
}

.transactionNote-loader-placeholder {
  border-radius: 4px;
}
