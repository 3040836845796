@import 'src/styles';

.topic-messages-container {
  padding: 100px 30px 30px 30px;

  .topic-messages-list {
    width: 100%;
  }

  .topic-options-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;

    .topic-option-block {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 100%;
      max-width: 300px;

      .topic-option-label {
        margin-left: 5px;
      }

      .topic-option-select {
        width: 100%;

        input {
          height: 38px !important;
          border-color: $light-neutral !important;
        }

        div:last-of-type {
          top: 40px !important;
        }

        div:first-of-type {
          top: 10px !important;
        }
      }
    }
  }
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 1000px;
}
