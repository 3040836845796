@import 'src/uikit/stylesheets/styles.scss';

.rule-engine-provider-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;

  .rule-engine-provider-container-header {
    @extend %heading-2;
    font-weight: bold;
  }

  .rule-engine-provider-container-button {
    display: flex;
    justify-content: flex-end;

  .rule-engine-provider-container-button-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px !important;
    height: 40px !important;
    padding: 6px 12px;
    box-sizing: border-box;
    }
  }
}

.rule-engine-provider-table-cell {
  vertical-align: middle;
  @extend %body;
}

.rule-engine-provider-table-cell-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rule-engine-provider-table-head {
  vertical-align: middle;
  @extend %body;
}

.rule-engine-provider-table-head-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

