@import 'src/styles';


.card-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;
}
.cards-table {
  width: 100%;
}

.table-responsive {
  width: 100%;
}

.table-wrapper {
  width: 100%;
}

.table-cell {
  min-width: 150px;
  width: fit-content;
  padding: 8px;
  border: 1px solid $light-neutral;
}

.table-row:first-child .table-cell:first-child {
  width: 30%;
}

.table-row:first-child .table-cell:last-child {
  width: 70%;
}

.table-input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.table-cell-input {
  width: 100%;
  input {
    height: 40px !important;
    border-color: $light-neutral !important;
  }

  div:last-of-type {
    top: 40px !important;
  }

  div:first-of-type {
    top: 10.5px !important;
  }
}

.card-actions-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px !important;
  height: fit-content !important;
  padding: 6px 12px;
  box-sizing: border-box;
}

.cards-tab-loader {
  height: calc(100vh - 209px) !important;
}
