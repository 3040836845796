@import 'src/styles';

.commission-transaction-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;
}

.commission-transaction-type-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.commission-transaction-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  padding: 20px 175px 0 175px;
}

.commission-transaction-block-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.commission-transaction-row-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}

.commission-transaction-select-transaction-type {
  width: 100%;
  min-width: 160px;
  max-width: 150px;
}

.commission-transaction-account-async-select {
  width: 100%;
  min-width: 300px;

  .async-select {
    height: 60px !important;
  }

  div:first-of-type {
    height: 100%;
    align-items: center;
    align-content: center;
  }
}

.commission-transaction-select {
  width: 100%;
  min-width: 160px;
  max-width: 200px;
}

.commission-transaction-input {
  width: 100%;
  min-width: 160px;
}

.commission-transaction-textarea {
  width: 100%;
  min-width: 150px;

  textarea {
    height: fit-content !important;
    padding: 10px 8px !important;
  }
}

.commission-transaction-submit-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.commission-transaction-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: fit-content !important;
  padding: 6px 12px;
  box-sizing: border-box;
}
