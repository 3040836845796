@import 'src/styles';

.customer-markups-filters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.customer-markups-filter-wrapper {
  width: 100%;
  max-width: 272px;
  min-width: 272px;
  margin-top: 10px;

  input {
    padding-left: 8px !important;
    border-color: $light-neutral !important;
  }
}

.customer-markups-filter-checkbox {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  gap: 10px;
  margin-top: 10px;
  background-color: inherit !important;
  padding: 0 !important;
}
