@import 'src/styles';

.scheme-rename-markup-group-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 500px;
  max-width: 600px;
  box-sizing: border-box;

  .scheme-rename-markup-group-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .scheme-rename-markup-group-container-header-close-icon {
      @extend %close-icon;
      color: $header;

      .background {
        color: transparent;
      }
    }
  }
}

.scheme-rename-markup-group-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 30px;

  .scheme-rename-markup-group-form-inputs-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 30px;

    .scheme-rename-markup-group-input {
      width: 100%;
    }
  }
}

.scheme-rename-markup-group-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.scheme-rename-markup-group-button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: fit-content !important;
  height: 40px !important;
  padding: 6px 12px;
  box-sizing: border-box;
}
