@import 'src/uikit/stylesheets/styles.scss';

.account-table-head {
  vertical-align: middle;
  @extend %body;
}

.account-table-head-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.account-table-head-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.multi-account-head-block {
  flex-direction: column;
  align-items: flex-start;
}

.add-currency-button {
  display: flex;
  justify-content: flex-end;
}

.head-block-center {
  justify-content: center;
}