@import '../stylesheets/styles';
@import '../Input/Input';

.input-wrapper-dropdown {
  @extend %input-shared;
  position: relative;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  .input::before {
    content: attr(data-currency-text); /* This could be an attribute consisting of black text */
    color: $active-default;
  }

  .input {
    padding: 0 25px 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      color: $header;
      -webkit-text-fill-color: $header;
      opacity: 1;
    }
  }

  .wallet-input {
    padding: 0 25px 0 60px;
  }

  .currency-label {
    position: absolute;
    top: 19px;
    left: 16px;
    @extend %body;
    color: $active-default;
  }

  .top-label {
    position: absolute;
    top: -16px;
    right: 20px;
    @extend %annotation;
    color: $dark-neutral;
  }

  .input-dropdown-menu {
    position: absolute;
    top: 60px;
    width: 100%;
    background-color: $white;
    box-shadow: 0 4px 38px rgba(34, 34, 34, 0.05);
    max-height: 350px;
    overflow-y: scroll;
    overflow-x: unset;
    z-index: 3;
  }

  .direction-up {
    top: -100px !important;
  }

  .input-dropdown-search {
    top: 0;
    position: sticky;
    padding: 20px 10px 20px 10px;
    background-color: $white;
  }

  .input-dropdown-input {
    input {
      pointer-events: auto;
    }
  }

  &.multi-select {
    .input {
      color: $active-default;
    }
    .dropdown-choice {
      display: flex;
      min-height: 40px;

      &.active {
        color: $active-default;
        background-color: $bg-2;
      }
    }
  }

  &.active.multi-select {
    .input {
      border-color: $active-default;
    }

    label {
      color: $active-default;
    }
  }

  &.active {
    .input {
      border-color: $dark;
    }

    .input-icon {
      color: $dark;
    }
  }

  &.hit {
    label {
      color: $hit;
    }
    .input {
      outline: none;
      border-color: $hit;
    }
    .input-icon {
      color: $hit;
    }
  }

  &.error {
    label {
      color: $error;
    }
    .input {
      outline: none;
      border-color: $error;
    }
    .input-icon {
      color: $error;
    }
  }
}

.disabled {
  cursor: not-allowed !important;

  input {
    cursor: not-allowed !important;
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@include mobile {
  .top-label {
    right: 6px;
  }
}
