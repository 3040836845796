@import '../stylesheets/styles';

$transition-time: 0.3s;

label {
  background-color: $white;
}

.uikit-container {
  position: relative;
  display: block;
  width: 100%;
  padding: 100px 0 50px 0;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);

  .container-header{
    position: absolute;
    top: 26px;
    left: 0;
    border-left: 2px solid $active-default;

    .container-header-text {
      word-break: break-word;
      padding: 0 30px;
      @extend %heading-1;
    }
  }
}

.uikit-container-max-width-unset {
  max-width: unset;
}

.compact {
  padding-left: 70px !important;
  transition: padding-left $transition-time ease-in-out;
}

.compact .uikit-container {
  max-width: none;
}

@include mobile {
  label {
    background-color: $background-color;
  }
  .uikit-container {
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    padding: 0;

    .container-header {
      position: initial;
      border-left: none;
      margin-bottom: 20px;

      .container-header-text {
        margin-left: 0;
      }
    }
  }
}