@import 'src/uikit/stylesheets/styles.scss';

.exchange-rates-table-cell {
  vertical-align: middle;
  @extend %body;
}

.exchange-rates-title-link {
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.exchange-rates-loader-placeholder {
  border-radius: 4px;
}

.exchange-rates-customers-count-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.exchange-rates-status-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .exchange-rates-status-checkbox {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    background-color: inherit !important;
    padding: 0 !important;
  }
}

.exchange-rates-table-cell-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}