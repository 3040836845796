svg {

  &.svg-phone-icon, &.svg-email-icon {
    circle {
      stroke: $active-default;
    }
  }

  &.svg-phone-icon {
    path {
      fill: $active-default;
    }
  }

  &.svg-email-icon {
    path {
      stroke: $active-default;
    }
  }

  &.back-icon,
  &.svg-bank-icon,
  &.svg-password-reset-icon
  {
    path {
      stroke: $active-default;
    }
  }

  &.svg-personal-details,
  &.svg-plus,
  &.svg-simple-icon,
  &.svg-standard-icon,
  &.svg-subtract,
  &.svg-config-change-password-icon
  {
    path {
      fill: $active-default;
    }
  }

  &.svg-declined {
    path {
      stroke: $active-default;
    }

    rect, circle {
      stroke: $active-default;
    }
  }

}
