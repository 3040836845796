@import '../stylesheets/styles';

%input-shared {
  position: relative;

  label {
    position: absolute;
    top: -10px;
    left: 8px;
    padding: 0 8px 2px 8px;
    border-radius: 6px;
    line-height: 11px;
    font-size: $font-size-smaller;
    color: $dark;
    z-index: 1;
  }

  .input {
    padding: 0 16px;
    height: 60px;
    min-width: 100%;
    max-width: 100%;
    border: 1px solid $light-neutral;
    background-color: $white;
    color: $dark;
    border-radius: 6px;
    transition: color 0.2s ease-in;

    &::placeholder {
      color: $neutral;
      font-size: 14px;
    }

    &:-webkit-autofill {
      -webkit-background-clip: text;
    }

    &:hover {
      color: $dark-neutral;
      border-color: $neutral;
    }

    &:focus {
      outline: none;
      color: $dark;
      border-color: $dark;
    }
  }

  .input-icon {
    position: absolute;
    color: $neutral;
    top: 20.5px;
    right: 10px;
    transition: all 0.2s ease-in;

    &:hover {
      color: $dark;
    }

    &.eye {
      cursor: pointer;
      display: flex;
      gap: 10px;
    }
  }

  &.hit {
    label {
      color: $hit;
    }
    .input {
      outline: none;
      border-color: $hit;
    }
    .input-icon {
      color: $hit;
    }
  }

  &.error {
    label {
      color: $error;
    }
    .input {
      outline: none;
      border-color:  $error;
    }
    .input-icon {
      color: $error;
    }
  }

  .input-sub-text {
    text-align: left;
    margin: 5px 5px 0 10px;
    font-size: $font-size-small;
    color: $dark;
    animation: 0.2s anim-lineUp ease-out;
  }

  .input-error {
    color: $error;
    text-align: left;
  }

  &.disabled {
    .input {
      background-color: $light;
      border: none;
      padding-right: 10px;

      &::placeholder {
        color: $dark;
      }
    }
  }
}

.d-none {
  display: none;
  z-index: -1;
  opacity: 0;
}

.input-wrapper {
  @extend %input-shared;

  .criteria-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 2px;
    margin: 5px 10px 0 10px;
    font-size: 12px;
    list-style: none;
    padding-left: 0;

    .criteria {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      width: fit-content;
      min-width: 49%;
      text-wrap: none;
      margin-bottom: 4px;
      color: $header;

      &:last-of-type {
        width: 100%;
      }

      svg {
        color: $neutral;
      }
    }

    .valid {
      color: $neutral;
      text-decoration: line-through;
      text-decoration-color: $neutral;

      svg {
        color: $hit;
      }
    }

    .invalid {
      svg {
        color: $error;
      }
    }
  }

  &.icon {
    .input {
      padding: 0 55px 0 16px;
    }
  }

  &:hover {
    .eye {
      color: $dark;
      &.error {
        color: $error;
      }
    }
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@include mobile {
  %input-shared {
    .input {
      background-color: inherit;
      padding-left: 20px;
    }

    .input-icon {
      right: 10px;
    }

    label {
      line-height: 12px;
    }

    .criteria-list {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
