@import 'src/styles';

.transaction-details-container {
  padding: 100px 30px 30px 30px;

  .transactions-list {
    width: 100%;
  }
}

.transaction-details-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.transaction-details-container {
  min-height: calc(100vh - 209px);
  padding: 80px 30px 30px 30px;
  justify-content: start;
}

.transaction-details-loader {
  height: calc(100vh - 209px) !important;
}

.delete-transaction-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}

.delete-transaction-button {
  color: $bg-danger !important;
  border-color: $bg-danger !important;
  width: fit-content !important;
  padding-right: 12px;
  padding-left: 12px;
  transition: color 0.3s, border-color 0.3s;
}

.delete-transaction-button:hover {
  color: darken($bg-danger, 15%) !important;
  border-color: darken($bg-danger, 15%) !important;
}

.delete-transaction-button:active {
  color: darken($bg-danger, 25%) !important;
  border-color: darken($bg-danger, 25%) !important;
}

.table-wrapper {
  width: 100%;
}

.table-cell {
  min-width: 150px;
  width: fit-content;
  padding: 8px;
  border: 1px solid $light-neutral;
}

.table-row:first-child .table-cell:first-child {
  width: 30%;
}

.table-row:first-child .table-cell:last-child {
  width: 70%;
}

.transaction-password-actions-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;
  gap: 15px;
}

.transaction-tab-actions-button {
  width: fit-content !important;
  flex-wrap: nowrap;
  padding-right: 12px;
  padding-left: 12px;
}

.transaction-details-table-input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.table-cell-input {
  width: 100%;
  input {
    height: 40px !important;
    border-color: $light-neutral !important;
  }

  div:last-of-type {
    top: 40px !important;
  }

  div:first-of-type {
    top: 10.5px !important;
  }
}

@include mobile {
  .transaction-details-container {
    padding: 0 0 15px 0;
  }
}