@import '../stylesheets/styles';

.button {
  height: 40px;
  border-radius: 6px;
  width: 113px;
  background-color: $active-default;
  border: none;
  color: $white;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.2s ease-in-out;

  &.disabled {
    background-color: $light-neutral;
    color: $white;
    pointer-events: none;
  }

  &.small {
    height: 32px;
    font-size: 16px;
    width: 113px;
  }

  &.medium {
    height: 38px;
    font-size: 16px;
    width: 113px;
  }

  &.large {
    height: 60px;
    width: 159px;
  }

  &.primary {
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: $bats-hover;
    }
    &:active {
      background-color: $active-active;
    }
  }

  &.tertiary {
    transition: background-color 0.2s ease-in-out;
    background-color: $bg-2;
    width: 137px;
    @extend %body;
    color: $header;
    &:hover {
      background-color: $active-hover;
      color: $white;
    }
    &:active {
      background-color: $active-default;
      color: $white;
    }
  }

  &.outline {
    background-color: transparent;
    color: $bg-dark;
    border: 1px solid $bg-dark;
    &:hover {
      color: $bats-hover;
      border-color: $bats-hover;
    }
    &:active {
      color: $active-default;
      border-color: $active-default;
    }
    &:disabled, &.disabled {
      color: $neutral;
      border-color: $neutral;
    }
  }

  &.light {
    background-color: $bg-dark;
    color: $bg-light;
    &:hover {
      background-color: mix($bg-light, $bg-dark, 95%);
    }
    &:active {
      background-color: mix($bg-light, $bg-dark, 85%);
    }
  }

  &.fullWidth {
    width: 100%;
  }
}

@include mobile {
  .button.primary, .button.outline {
    width: 100%;
  }
}

