@import 'src/styles';


.representatives-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;
}
.representatives-table {
  width: 100%;
}

.table-responsive {
  width: 100%;
}

.table-wrapper {
  width: 100%;
}

.table-cell {
  min-width: 150px;
  width: fit-content;
  padding: 8px;
  border: 1px solid $light-neutral;
}

.table-row:first-child .table-cell:first-child {
  width: 30%;
}

.table-row:first-child .table-cell:last-child {
  width: 70%;
}

.table-input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.table-cell-input {
  width: 100%;
  input {
    height: 40px !important;
    border-color: $light-neutral !important;
  }

  div:last-of-type {
    top: 40px !important;
  }

  div:first-of-type {
    top: 10.5px !important;
  }
}

.representatives-actions-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px !important;
  height: fit-content !important;
  padding: 6px 12px;
  box-sizing: border-box;
}

.representatives-tab-loader {
  height: calc(100vh - 209px) !important;
}

.representatives-details-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.representatives-actions-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px !important;
  height: fit-content !important;
  padding: 6px 12px;
  box-sizing: border-box;
}

.representatives-add-header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 18px 10px;
  margin-bottom: 29px;
  border-bottom: 1px solid $light-neutral;
  width: 330px;
  height: 61px;
}

.scheme-representatives-wrapper {
  .scheme-success-header {
    text-align: center;
    margin-bottom: 30px;
  }

  & .next-button {
    width: 80px;
  }
}

.representatives-popUp-header {
  @extend %heading-2;
  margin-bottom: 0;
}

.representatives-icon {
  @extend %close-icon;
  color: $header;
  .background {
    color: transparent;
  }
}

%checkbox-container {
  border: 1px solid $light-neutral;
  border-radius: 6px;
}

.add-representatives {
  padding: 20px;

  .representatives-email {
    margin-bottom: 30px;
  }
}

.representatives-notVerified {
  width: 394px;
}

.permission {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 20px;
  .representatives-email {
    margin-bottom: 30px;
    color: $dark;

    &.disabled {
      color: $light-neutral;
    }
  }

  .representatives-checkbox-wrapper {
    @extend %checkbox-container;
    padding: 10px
  }

  .permission-checkboxes-wrapper {
    @extend %checkbox-container;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .representatives-checkbox {
    width: 100%;
    color: $neutral;
    &.active {
      background-color: transparent;
    }
  }

  .full-checkbox {
    @extend %checkbox-container;
    color: $dark;
    margin-bottom: 21px;
    padding: 19px 14px;
  }

  .sms-code-wrapper {
    margin-bottom: 20px;
  }
}

.delete {
  .sms-code-wrapper {
    margin-bottom: 20px;
  }
}
