@import 'src/styles';

.commission-transactions-filters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.commission-transactions-filter-wrapper {
  width: 100%;
  max-width: 275px;
  min-width: 275px;
}

.commission-transactions-filter-search-select-wrapper {
  width: 100%;
  max-width: 275px;
  min-width: 275px;
  input {
    padding-left: 8px !important;
    height: 60px !important;
    border-color: $light-neutral !important;
  }
}

.commission-transactions-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;

  .commission-transactions-date-range-filters-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 22px;
  }
}

.export-commission-transactions-reports-actions-wrapper {
  display: flex;
  gap: 20px;
}

.commission-transactions-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  padding-right: 12px;
  padding-left: 12px;
  gap: 5px;
}
