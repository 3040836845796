@import 'src/styles';

.blacklists-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;

  .blacklists-list {
    width: 100%;
  }

  .blacklist-container-pop-up {
    width: 500px;
  }
}
