@import 'src/uikit/stylesheets/styles.scss';

.representatives-table-cell {
  vertical-align: middle;
  white-space: pre-wrap;
  @extend %body;
}

.representatives-table-cell-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.disabled {
  cursor: not-allowed;
}

.representatives-customer-id-text {
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.representatives-loader-placeholder {
  border-radius: 4px;
}
