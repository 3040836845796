@import 'src/styles';


.table-wrapper {
  width: 100%;

  .table-body {
    .table-row {
      .table-cell  .input-icon {
        top: 10.5px
      }

      .table-cell-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: large;
      }
    }
  }
}

.table-cell-input-dropdown-search {
  width: 100%;
  input {
    padding-left: 8px !important;
    height: 40px !important;
    border-color: $light-neutral !important;
  }
}
