@import 'src/uikit/stylesheets/styles.scss';

.account-table-cell {
  vertical-align: middle;
  @extend %body;
}

.account-table-checkbox {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  background-color: inherit !important;

  &:first-child {
    width: fit-content;
  }
}

.account-table-cell-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.disabled {
  cursor: not-allowed;
}

.account-number-text {
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.account-loader-placeholder {
  border-radius: 4px;
}
