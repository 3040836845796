@import 'src/styles';

.scheme-add-new-limit-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 500px;
  max-width: 600px;
  max-height: 85vh;
  box-sizing: border-box;
  overflow: auto;

  .scheme-add-new-limit-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .scheme-add-new-limit-container-header-close-icon {
      @extend %close-icon;
      color: $header;

      .background {
        color: transparent;
      }
    }
  }
}

.scheme-add-new-limit-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 30px;

  .scheme-add-new-limit-form-inputs-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 30px;

    .scheme-add-new-limit-form-checkbox-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: -10px;

      .scheme-add-new-limit-form-status-checkbox {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        background-color: inherit !important;
        padding: 0 !important;
      }
    }

    .scheme-add-new-limit-form-amount-inputs-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      gap: 20px;

      .scheme-add-new-limit-form-amount-inputs-and-currency-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 240px;
        box-sizing: border-box;

        .scheme-add-new-limit-form-amount-input {
          width: 100%;
          height: 40px !important;

          input {
            width: inherit;
            height: 40px !important;
          }

          svg {
            top: 10px !important;
          }
        }

        .scheme-add-new-limit-form-hold-or-reject-currency {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          height: 40px !important;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 6px;
          background: $light;
          @extend %body;
          color: $black;
          text-align: center;
        }
      }
    }

    .error-inputs-wrapper {
      gap: 40px !important;
    }
  }
}

.scheme-add-new-limit-input-dropdown-search {
  width: 100%;
  input {
    padding-left: 8px !important;
    height: 40px !important;
    border-color: $light-neutral !important;
  }

  .input-icon {
    top: 12px !important;
  }
}

.scheme-add-new-limit-input {
  width: 100%;
  input {
    height: 40px !important;
    border-color: $light-neutral !important;
  }

  div:last-of-type {
    top: 40px !important;
  }

  div:first-of-type {
    top: 10.5px !important;
  }
}

.scheme-add-new-limit-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 10px;
}

.scheme-add-new-limit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: fit-content !important;
  height: 40px !important;
  padding: 6px 12px;
  box-sizing: border-box;
}
