@import 'src/uikit/stylesheets/styles.scss';

.document-table-cell {
  @extend %body;

  .document-table-cell-info {
    display: flex;
    flex-direction: column;
    .document-table-cell-info-date {
      @include caption();
    }
  }

  .document-table-cell-expired {
    color: $bg-danger;
    font-weight: bold;
  }

  .document-table-cell-actions {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .document-table-cell-actions-button {
      padding-right: 12px;
      padding-left: 12px;
      transition: color 0.3s, border-color 0.3s;
    }

    .document-table-cell-actions-button-delete {
      padding-right: 12px;
      padding-left: 12px;
      transition: color 0.3s, border-color 0.3s;
      color: $bg-danger !important;
      border-color: $bg-danger !important;
    }

    .document-table-cell-actions-button-delete:hover {
      color: darken($bg-danger, 15%) !important;
      border-color: darken($bg-danger, 15%) !important;
    }

    .document-table-cell-actions-button-delete:active {
      color: darken($bg-danger, 25%) !important;
      border-color: darken($bg-danger, 25%) !important;
    }

  }

  .document-table-cell-link {
      cursor: pointer;
      color: $active-default;
      text-decoration: none;

      :hover {
        color: $active-hover;
      }

      :active {
        color: $active-active;
      }
  }
}

.document-loader-placeholder {
  border-radius: 4px;
}
