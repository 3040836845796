@import 'src/styles';

.customer-aml-notes-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;


  .customer-aml-notes-select {
    width: 100%;

    input {
      height: 38px !important;
      border-color: $light-neutral !important;
    }

    div:last-of-type {
      top: 40px !important;
    }

    div:first-of-type {
      top: 10px !important;
    }
  }

  .customer-aml-notes-container-add-note {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .customer-aml-notes-container-add-note-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px !important;
      height: fit-content !important;
      padding: 6px 12px;
      box-sizing: border-box;
    }
  }

  .customer-aml-notes-container-input {
    width: 100%;
  }

  .aml-notes-attachment {
    margin-top: 10px;
    width: 100%;

    .input {
      padding: 15px;

      .files-list-wrapper {
        width: 100%;
      }
    }
  }
}

.customer-aml-notes-tab-loader {
  height: calc(100vh - 209px) !important;
}