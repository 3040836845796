@import 'src/styles';

.scheme-add-new-markup-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  padding: 40px 40px 40px 60px;

  h3 {
    margin-bottom: 3px;
    margin-right: 10px;
  }

  .scheme-add-new-markup-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .scheme-add-new-markup-container-header-close-icon {
      @extend %close-icon;
      color: $header;

      .background {
        color: transparent;
      }
    }
  }
}

.scheme-add-new-markup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  margin-top: 20px;

  .scheme-add-new-markup-form-inputs-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 30px;

    .error-inputs-wrapper {
      gap: 40px !important;
    }
  }
}

.scheme-add-new-markup-input-dropdown-search {
  width: 100%;
  max-width: 175px;
  input {
    padding-left: 8px !important;
    height: 40px !important;
    border-color: $light-neutral !important;
  }

  .input-icon {
    top: 12px !important;
  }
}

.scheme-add-new-markup-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
}

.scheme-add-new-markup-button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: fit-content !important;
  height: 40px !important;
  padding: 6px 12px;
  box-sizing: border-box;
}

.scheme-add-new-markup-status-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  margin-top: 7px;

  .scheme-add-new-markup-status-checkbox {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    background-color: inherit !important;
    padding: 0 !important;
  }
}
