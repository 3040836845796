@import 'src/styles';

$transition-time: 0.3s;

nav.navbar {
position: fixed;
  top: 80px;
  left: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  z-index: 1000;
  overflow-y: auto;
}

.navigation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  gap: 15px;
  transition: width $transition-time ease-in-out;

  a {
    height: 24px;
  }
  a > p {
    transition: width $transition-time ease-in-out, font-size $transition-time ease-in-out;
  }
}

.navbar-messages-button-wrapper {
  display: flex;
  padding-left: 2px;
}

.navbar-messages-button-unread-count {
  font-size: 11px;
  position: relative;
  left: 1px;
  bottom: 1px;
  font-weight: bold;
  text-align: start;
}

.navigation-wrapper.collapsed {
  width: 50px;
  a > p {
    white-space: nowrap;
    width: 0;
    font-size: 0;
  }
}

@include desktop-breakpoint {
  .navigation-wrapper {
    width: 200px;
  }
}

@include non-desktop {
  nav.navbar {
    width: fit-content;
  }
}

@include mobile {
  nav.navbar.collapsed {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 79px;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    overflow: auto;
    padding: 20px;
    background-color: $white;
    z-index: 5;

    .user-panel {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }


  }

  .navigation-wrapper {
    overflow: unset;
    .account-dropdown-container {
      order: -1;
    }
  }

  nav.navbar {
    display: none;
  }
}