@import './src/styles';

.back-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
  width: fit-content;

  svg {
    margin-top: 2px;
    color: $bg-light;
    transition: all 0.2s ease-in;
  }

  .back-button-text {
    @extend %heading-2;
    font-weight: 600;
    color: $dark-neutral;
    transition: all 0.2s ease-in;
  }

  &:hover {
    svg {
      color: $active-hover;
    }

    .back-button-text {
      color: $active-hover;
    }
  }

  &:active {
    svg {
      color: $active-default;
    }

    .back-button-text {
      color: $active-default;
    }
  }
}

@include mobile {
  .back-button-container {
    margin-bottom: 30px;
  }
}