@import 'src/uikit/stylesheets/styles.scss';

.limit-group-details-table-cell {
  vertical-align: middle;
  @extend %body;
  padding: 0.75rem !important;
}


.limit-group-details-table-loader-placeholder {
  border-radius: 4px;
}

.limit-group-details-table-customers-count-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.limit-group-details-table-status-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .limit-group-details-table-status-checkbox {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    background-color: inherit !important;
    padding: 0 !important;
  }
}

.limit-group-details-table-date-and-time {
  @extend %caption;
  color: $neutral;
  text-align: left;
  white-space: pre-line;
  font-style: italic;
}
