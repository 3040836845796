@import 'src/uikit/stylesheets/styles.scss';

.markup-group-details-table-cell {
  vertical-align: middle;
  @extend %body;
  padding: 0.75rem !important;
}

.markup-group-details-table-loader-placeholder {
  border-radius: 4px;
}

.markup-group-details-table-customers-count-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.markup-value-wrapper {
  display: flex;
  flex-direction: row;
  border: 1px solid $light-neutral;
  border-radius: 6px;
  max-width: 240px;

  .table-cell-input input {
    border: none !important;
    max-width: 200px;
  }

  .markup-value-symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $light;
    padding: 8px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
