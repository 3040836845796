@import 'src/styles';

.messages-topics-container {
  padding: 100px 30px 30px 30px;

  .messages-topics-list {
    width: 100%;
  }

  .new-message-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content !important;
    padding-right: 12px;
    padding-left: 12px;
    gap: 5px;
    margin-bottom: 15px;
  }
}
