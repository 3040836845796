@import 'src/uikit/stylesheets/styles.scss';

.customer-markup-details-table-cell {
  vertical-align: middle;
  @extend %body;
  padding: 0.75rem !important;
  white-space: pre-wrap !important;
}

.customer-markup-details-table-loader-placeholder {
  border-radius: 4px;
}
