@import 'src/styles';


.company-structure-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  .company-structure-form-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;

    .company-structure-form-buttons-add {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 215px !important;
      height: fit-content !important;
      padding: 6px 12px;
      box-sizing: border-box;
    }
    .company-structure-form-buttons-save {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px !important;
      height: 40px !important;
      padding: 6px 12px;
      box-sizing: border-box;
    }
  }

  .company-structure-form-table {
    width: 100%;
  }
}

.company-structure-tab-loader {
  height: calc(100vh - 209px) !important;
}
