@import 'src/styles';


.customer-tariffs-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;
}

.customer-tariff-group-details-table-wrapper {
  width: 100%;
}

.customer-tariff-group-details-table-body {
  width: 100%;
}

.customer-tariff-group-details-table-subsection {
  font-weight: bold;
  font-size: large;
}

.customer-tariff-group-details-table-row {
  width: 100%;
}

.customer-tariff-group-details-table-cell {
  min-width: 150px;
  width: fit-content;
  padding: 8px;
  border: 1px solid $light-neutral;
  white-space: pre-line;
  word-break: break-word;
}

.customer-tariff-group-details-table-row .customer-tariff-group-details-table-cell:first-child {
  max-width: 25%;
  width: 100%;
}

.customer-tariff-group-details-table-row .customer-tariff-group-details-table-cell:last-child {
  max-width: 75%;
  width: 100%;
}

.customer-tariff-group-details-table-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;

  .customer-tariff-group-details-edit-button {
    width: 72px !important;
    padding-right: 12px;
    padding-left: 12px;
  }

  .input-icon {
    top: 10.5px
  }

  .customer-tariff-group-details-table-cell-input {
    width: 100%;
    input {
      height: 40px !important;
      border-color: $light-neutral !important;
    }

    div:last-of-type {
      top: 40px !important;
    }

    div:first-of-type {
      top: 10.5px !important;
    }
  }
}


.tariffs-actions-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px !important;
  height: 40px !important;
  padding: 6px 12px;
  box-sizing: border-box;
}

.tariffs-details-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 40px !important;
}
