@import 'src/styles';

.markup-groups-container {
  padding: 80px 30px 30px 30px;
}

.markups-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 30px;
}
