@import 'src/styles';

.transit-transactions-filters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.transit-transactions-filter-wrapper {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
}

.transit-transactions-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0;

  .transit-transactions-date-range-filters-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 22px;
  }
}

.export-transit-transactions-reports-actions-wrapper {
  display: flex;
  gap: 20px;
}

.transit-transactions-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  padding-right: 12px;
  padding-left: 12px;
  gap: 5px;
}
