@import 'src/styles';

.create-user-container {
  min-height: calc(100vh - 209px);
  padding: 80px 30px 30px 30px;
  justify-content: start;
}

.create-user-loader {
  height: calc(100vh - 209px) !important;
}

.create-user-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.create-user-form-inputs-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 450px;
  gap: 20px;
}

.create-user-form-input {
  width: 100%;
}

.create-user-form-password-input {
  width: 100%;
  margin-bottom: 115px;
}

.create-user-form-password-input-error {
  width: 100%;
  margin-bottom: 135px;
}


@include mobile {
  .create-user-container {
    padding: 0 0 15px 0;
  }
}