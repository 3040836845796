.statement-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  gap: 15px;

  .account-number-selector {
    height: 40px;

    .currency-label {
      top: 9.5px !important;
    }

    .input-icon {
      top: 10.5px !important;
    }

    input {
      height: 40px;
    }
  }

  .statement-container-button {
    padding-right: 12px;
    padding-left: 12px;
    transition: color 0.3s, border-color 0.3s;
    width: fit-content !important;
  }
}
