@import 'src/uikit/stylesheets/styles';

.file-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.file-select-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px !important;
  height: 40px !important;
  padding: 6px 12px;
  box-sizing: border-box;
}

.file-input {
  display: none;
}

