@import 'src/styles';

.limit-group-details-container {
  padding: 100px 30px 30px 30px;

  .limit-group-details-actions-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 20px;
  }

  .limit-group-details-list {
    width: 100%;
  }
}

.limit-group-details-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  padding-right: 14px;
  padding-left: 14px;
  margin-bottom: 20px;
  gap: 5px;
  font-weight: 600;
}
