@import 'src/uikit/stylesheets/styles.scss';

.markup-groups-table-cell {
  vertical-align: middle;
  @extend %body;
}

.markup-groups-title-link {
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.markup-groups-loader-placeholder {
  border-radius: 4px;
}

.markup-groups-customers-count-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.markup-groups-status-checkbox {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  background-color: inherit !important;
  padding: 0 !important;
}

.markup-groups-table-cell-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}