@import 'src/styles';

.limit-groups-table-head {
  vertical-align: middle;
  @extend %body;
}

.limit-groups-table-head-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.arrow-icon {
  cursor: pointer;

  .up, .down {
    color: $black;
  }

  &.ASC {
    .up {
      color: $black;
    }

    .down {
      color: $light-neutral;
    }
  }

  &.DESC {
    .up {
      color: $light-neutral;
    }

    .down {
      color: $black;
    }
  }

  &:hover {
    .up, .down {
      color: $active-hover;
    }
  }
}
