@import 'src/uikit/stylesheets/styles.scss';

.card-table-cell {
  vertical-align: middle;
  @extend %body;
}

.card-table-cell-actions {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.card-id-text {
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.card-loader-placeholder {
  border-radius: 4px;
}
