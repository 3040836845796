@import 'src/styles';

.configuration-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;

  .accounts-list {
    width: 100%;
  }
}

.loader-placeholder {
  border-radius: 6px;
  height: 11px;
  width: 85px;
  background: linear-gradient(-90deg, $light-neutral 0%, lighten($light-neutral, 9%) 50%, $light-neutral 100%);
  background-size: 400% 400%;
  animation: stemPulse 1.2s ease-in-out infinite;
}

