@import 'src/styles';

.transaction-details-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 30px;
}

.transaction-details-tables-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  gap: 20px;
}

.table-wrapper {
  width: 100%;
}

.table-cell {
  min-width: 150px;
  width: fit-content;
  padding: 8px;
  border: 1px solid $light-neutral;
}

.empty-rule-engine-cell {
  text-align: center;
}

.table-row:first-child .table-cell:first-child {
  width: 30%;
}

.table-row:first-child .table-cell:last-child {
  width: 70%;
}

.transaction-details-actions-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0;
  gap: 20px;
}

.transaction-details-actions-button {
  width: fit-content !important;
  flex-wrap: nowrap;
  padding-right: 12px;
  padding-left: 12px;
}

.transaction-details-table-input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.transaction-details-save-button {
  width: 72px !important;
  padding-right: 12px;
  padding-left: 12px;
}

.row-label {
  font-weight: bold;
}

.table-cell-input {
  width: 100%;
  input {
    height: 40px !important;
    border-color: $light-neutral !important;
  }

  div:last-of-type {
    top: 40px !important;
  }

  div:first-of-type {
    top: 10.5px !important;
  }
}

.attach-doc-hint-text {
  display: inline-block;
  margin: 0;
  font-size: 12px;
  font-style: italic;
  color: #888;
}

.documents-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.document-item-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px
}

.document-item-label {
  text-align: left;
  &:hover {
    text-decoration: underline;
  }
}

.document-item-actions-wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  .document-item-action-edit {
    svg {
      margin: 0;
      color: $active-default;
      &:hover {
        color: $active-hover;
      }
    }
  }
  .document-item-action-remove {
    svg {
      margin: 0;
      color: $bg-danger;
      &:hover {
        color: $error;
      }
    }
  }
}

.document-item-image {
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.document-item-checkbox {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  background-color: inherit !important;
  padding: 0 !important;
}

.transaction-file-selector-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.transaction-file-selector-button {
  width: fit-content;
  height: 40px;
  padding: 0 14px;
}

.nav-link-text {
  width: fit-content;
  height: fit-content;
  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.list-value {
  display: inline-block;
  padding: 6px 0;
  width: 100%;
  word-break: break-word;
}

.rule-engine-header {
  margin: 30px 0 10px 0;
  font-weight: 600;
  font-size: large;
}

.advanced-details-table-head {
  vertical-align: middle;
  @extend %body;
}

.advanced-details-table-head-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.advanced-details-table-cell {
  min-width: 125px;
  vertical-align: middle;
  @extend %body;
  word-break: break-word;
}

.advanced-details-table-cell-button {
  @extend %body;
  width: fit-content;
  height: fit-content;
  text-align: left;

  color: $active-default;
  text-decoration: none;

  :hover {
    color: $active-hover;
  }

  :active {
    color: $active-active;
  }
}

.advanced-details-payment-limits-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

@include mobile {
  .transaction-details-container {
    padding: 0 0 15px 0;
  }
}