@import '../stylesheets/styles';

.icon-button-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button {
  display: flex;
  all: unset;
  cursor: pointer;
  align-items: center;
  color: $dark-neutral;
  transition: all 0.2s ease-in;

  .icon-button-label {
    display: contents;
  }

  svg {
    vertical-align: bottom;
    margin-right: 5px;
    transition: all 0.2s ease-in;
    color: $light-neutral;
  }

  &:hover {
    color: $bats-hover;

    svg {
      color: $bats-hover;
    }
  }

  &:active, &.active {
    color: $active-default;

    svg {
      color: $active-default;
    }
  }

  &:disabled, &.disabled {
    color: $neutral;
    pointer-events: none;

    svg {
      color: $neutral;
    }
  }

  .message-icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in;

    .unread-message-count {
      position: absolute;
      text-align: center;
      top: -7px;
      right: 4px;
      padding: 0.5px 1px;
      min-width: 15px;
      min-height: 8px;
      border-radius: 50px;
      border: none;
      background-color: $error;
      @extend %annotation;
      font-weight: 700;
      font-size: 10px;
      color: $white;
      transition: all 0.2s ease-in;
    }
  }
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  background-color: $white;
  border-radius: 6px;
  z-index: 9999;
  transition: all 0.2s ease-in;
  white-space: nowrap;
  padding: 0;


  .tooltip-text {
    margin: 0;
    @extend %caption;
    color: $active-default;
    white-space: nowrap;
    writing-mode: vertical-rl;
    text-align: center;
  }
}

.icon-button-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

