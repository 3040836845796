@import 'styles';

.blacklist-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 32px;
  align-items: flex-end;

  .blacklist-container-header {
    width: 100%;
    white-space: nowrap;
    @extend %heading-2;


    .blacklist-container-header-icon {
      @extend %close-icon;
      color: $header;
      .background {
        color: transparent;
      }
    }
  }



  .blacklist-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .blacklist-form-input {
      width: 100%;
      min-width: 150px;

      textarea {
        height: fit-content !important;
        padding: 10px 8px !important;
      }
    }

    .blacklist-form-button-wrapper {
      width: 100%;
      display: flex;
        justify-content: flex-end;

      .blacklist-form-button {
        width: fit-content;
        padding-right: 12px;
        padding-left: 12px;
      }
    }
  }
}