@import 'src/styles';

.create-tariff-group-container {
  padding: 100px 30px 30px 30px;

  .create-tariff-group-actions-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 20px;

  }

  .create-tariff-group-list {
    width: 100%;
  }
}

.create-tariff-group-form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.create-tariff-group-form-table-wrapper {
  width: 100%;
}

.create-tariff-group-form-table-cell {
  min-width: 150px;
  width: fit-content;
  padding: 8px;
  border: 1px solid $light-neutral;
  white-space: pre-line;
  word-break: break-word;
}

.create-tariff-group-form-subsection {
  font-weight: bold;
  font-size: large;
}

.create-tariff-group-form-table-body {
  width: 100%;
}

.create-tariff-group-form-table-row {
  width: 100%;
}

.create-tariff-group-form-table-row .create-tariff-group-form-table-cell:first-child {
  max-width: 25%;
  width: 100%;
}

.create-tariff-group-form-table-row .create-tariff-group-form-table-cell:last-child {
  max-width: 75%;
  width: 100%;
}

.create-tariff-group-form-table-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .input-icon {
    top: 10.5px
  }

  .create-tariff-group-form-table-cell-input {
    width: 100%;
    input {
      height: 40px !important;
      border-color: $light-neutral !important;
    }

    div:last-of-type {
      top: 40px !important;
    }

    div:first-of-type {
      top: 10.5px !important;
    }
  }
}

.create-tariff-group-form-table-actions-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 10px;

  .create-tariff-group-form-table-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content !important;
    padding-right: 14px;
    padding-left: 14px;
    margin-bottom: 20px;
    gap: 5px;
    font-weight: 600;
  }
}
