@import 'src/styles';

.attach-transaction-document-container {
  width: 100%;
  max-width: 665px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 32px;
  align-items: flex-end;

  .attach-transaction-document-container-header {
    width: 100%;
    white-space: nowrap;
    @extend %heading-2;


    .attach-transaction-document-container-header-icon {
      @extend %close-icon;
      color: $header;
      .background {
        color: transparent;
      }
    }
  }



  .attach-transaction-document-container-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .attach-transaction-document-container-form-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      margin-bottom: 45px;

      .attach-transaction-document-container-form-wrapper-input {
        width: 100%;
      }

      .attach-transaction-document-container-form-wrapper-attach {
        width: 100%;
        min-width: 250px;
        height: 40px;
      }

      .attach-transaction-document-container-form-wrapper-attach-input .input {
        min-height: 0;
        height: 40px;
      }
      .attach-transaction-document-container-form-wrapper-attach-input-error{
        border-color: red;

      }
      .attach-transaction-document-container-form-wrapper-attach-input-error-text {
        text-align: left;
        margin: 5px 5px 0 10px;
        font-size: $font-size-small;
        animation: 0.2s anim-lineUp ease-out;
        color: $error;
      }

      .attach-transaction-document-checkbox {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        background-color: inherit !important;
        padding: 0 !important;
      }
    }

    .attach-transaction-document-container-form-button-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .attach-transaction-document-container-form-button {
        width: fit-content;
        padding: 0 14px;
      }
    }

    .attach-transaction-document-container-form-note {
      width: 100%;

      textarea {
        height: fit-content !important;
        padding: 10px 8px !important;
      }
    }
  }
}
