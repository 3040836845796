@import '../stylesheets/styles';
@import '../Input/Input';

.input-wrapper-file {
  @extend %input-shared;

  .input-hint-text {
    position: absolute;
    margin: 3px 17px 7px 17px;
    font-size: $font-size-small;
    color: $dark;
  }

  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 7px 16px 16px;
    min-height: 60px;
    height: auto;
  }
  .files-list-wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .files-item {
      position: relative;
      @extend %annotation;
      color: $header;
      background-color: $bg-2;
      gap: 4px;
      padding: 1px 4px;
      display: flex;
      border-radius: 4px;
      justify-content: space-between;
      transition: all 0.2s ease-in;

      &:hover {
        color: $active-hover;
        .attach-doc-tooltip {
          visibility: visible;
          opacity: 1;
          transition: all 0.2s ease-in;
        }
      }

      .file-text {
        cursor: context-menu;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .delete-file-icon {
        min-width: 20px;
        min-height: 20px;
        cursor: pointer;
        color: $active-default;
        transition: all 0.2s ease-in;
        .background {
          color: $bg-2;
        }

        &:hover {
          color: $white;
          .background {
            color: $active-default;
          }
        }
      }

      .attach-doc-tooltip {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        text-align: center;
        top: -45px;
        background-color: $white;
        border-radius: 6px;
        padding: 10px 15px;
        z-index: 2;

        .attach-doc-tooltip-text {
          @extend %caption;
          color: $active-hover;
          white-space: nowrap;
        }
      }
    }
  }

  .upload-file-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 28px;
    height: 28px;
    transition: all 0.2s ease-in;
    margin-left: 30px;

    &:hover {
      background-color: $bg-2;
      .upload-file-icon {
        color: $active-default;
      }
    }

    .attach-doc-file-input {
      position: absolute;
      cursor: pointer;
      width: 28px;
      height: 28px;
      opacity: 0;
    }
    .upload-file-icon {
      color: $neutral;
      transition: all 0.2s ease-in;
    }
  }

  .attach-doc-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }

  &.active {
    .input {
      border-color: $dark;
    }

    .upload-file-button {
      background-color: $active-default;
      .upload-file-icon {
        color: $white;
      }

      &:hover {
        background-color: $bg-2;
        .upload-file-icon {
          color: $active-default;
        }
      }
    }
  }

  &.error {
    .label {
      color: $error;
    }
    .input {
      border-color: $error;
    }

    .files-list-wrapper {
      .files-item {
        color: $error;
      }
    }

    .upload-file-button {
      background-color: $error;
      .upload-file-icon {
        color: $white;
      }
    }
  }
}

@include mobile {
  .input-wrapper-file {
    .input {
      padding: 16px;
      .files-list-wrapper {
        padding-left: 0;
        width: fit-content;
        max-width: 70%;
      }
      .upload-file-button {
        margin-right: 0;
      }
    }
    .input-hint-text {
      line-height: 140%
    }
  }
}

input[type="file"]::-webkit-file-upload-button{
  all: unset;
}