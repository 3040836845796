@import 'src/styles';

.crypto-transaction-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;
}

.crypto-transaction-type-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.crypto-transaction-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  padding: 20px 175px 0 175px;
}

.crypto-transaction-block-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.crypto-transaction-row-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}

.crypto-transaction-select-transaction-type {
  width: 100%;
  max-width: 150px;
  min-width: 150px;
}

.crypto-transaction-select-currency {
  width: 100%;
  min-width: 150px;
  max-width: 250px;
  input {
    padding-left: 8px !important;
    height: 60px !important;
    border-color: $light-neutral !important;
  }
}

.crypto-transaction-input {
  width: 100%;
  min-width: 150px;
  max-width: 250px;
}

.crypto-transaction-textarea {
  width: 100%;
  min-width: 150px;

  textarea {
    height: fit-content !important;
    padding: 10px 8px !important;
  }
}

.crypto-transaction-attachment {
  margin-top: 10px;
  width: 100%;

  .input {
    padding: 15px;

    .files-list-wrapper {
      width: 100%;
    }
  }
}

.crypto-transaction-submit-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.crypto-transaction-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: fit-content !important;
  padding: 6px 12px;
  box-sizing: border-box;
}
